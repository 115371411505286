import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useLocation } from "@reach/router"
import { faRss } from "@fortawesome/free-solid-svg-icons"

import { useLunr } from "../../hooks/useLunr"

import Layout from "../../components/en/Layout"
import Breadcrumbs from "../../components/en/Breadcrumbs"
import HomeSectionInfinite from "../../components/en/HomeSectionInfinite"
import SEO from "../../components/en/SEO"

import LoadingIcon from "../../images/icons/Loading"

const Search = ({ location }) => {
  const l = useLocation()

  const data = useStaticQuery(graphql`
    query {
      allLocalSearchCards {
        edges {
          node {
            index
            store
          }
        }
      }
    }
  `)

  const { index, store } = data.allLocalSearchCards.edges[0].node
  const [query, setQuery] = useState(null)
  let results = useLunr(query, index, store)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    const url = new URLSearchParams(window.location.search)

    if (l.state) {
      setQuery(l.state.query)
    } else if (url) {
      setQuery(url.get("s"))
    }

    setTimeout(function () {
      setLoading(false)
    }, 1000)
  }, [location, query, results, l])

  const renderResults = results => {
    if (results.length === 0) {
      return (
        <div className="home-section" style={{ marginTop: 40 }}>
          <p className="search-no-results">
            No results matching{" "}
            <span style={{ color: "#696969" }}>"{query}"</span>
          </p>
        </div>
      )
    }

    return (
      <HomeSectionInfinite
        name="Results"
        cards={results}
        icon={faRss}
        style={{ marginTop: 25 }}
        isSearch={true}
        customClass="home-section-card-list"
      />
    )
  }

  if (loading) {
    return (
      <Layout>
        <SEO
          title="Search"
          canonical={`${process.env.GATSBY_HOST_URL}/en/search`}
          image="https://nizam.app/nizam-cover.jpg"
        />

        <div
          className="main-content feed-main-content"
          style={{ marginTop: 117 }}
        >
          <Breadcrumbs
            currentPage="Search"
            style={{ marginTop: 0 }}
            search={query}
          />

          <div className="infinite-loading-el" style={{ marginTop: 40 }}>
            <div className="loading-spinner">
              <LoadingIcon />
            </div>
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <SEO
        title="Search"
        canonical={`${process.env.GATSBY_HOST_URL}/en/search`}
        image="https://nizam.app/nizam-cover.jpg"
      />

      <div
        className="main-content feed-main-content"
        style={{ marginTop: 117 }}
      >
        <Breadcrumbs
          currentPage="Search"
          style={{ marginTop: 0 }}
          search={query}
        />

        {renderResults(results.filter(r => r.language === "en"))}
      </div>
    </Layout>
  )
}

export default Search
